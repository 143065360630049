.mainMenu {
	@include fadeOut($durationBase, $easeBase);
	//background-color: $colorBgModal;
	display: none;
	left: 0;
	min-height: 100vh;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
	pointer-events: none;

	@include media($mediumLayout) {
		visibility: visible;
		//opacity: 1;
		z-index: 4;
		min-height: 0;
		display: block;
		background-color: transparent;
		position: fixed;
		@include fadeIn($durationBase, $easeBase);

		.header.js-collapsed:not(.js-coveredByMenu) + & {
			@include fadeOut($durationBase, $easeBase);
		}
	}

	&.js-fixed {
		// position: fixed;
	}

	&.js-firstActiveStep {
		display: block;
		will-change: opacity, visibility, height;
		//@include fadeIn($durationFast);
	}

	&.js-secondActiveStep {

	}

	&.js-active {
		@include fadeIn();
		position: absolute;
	}


	&__container {
		@include mediaMap($sizeVariants, mainMenuVerticalSpacings);
		text-align: center;
		overflow: hidden;
		height: 0;
		@include ease(height, $durationBase, $easeBase);

		.mainMenu.js-firstActiveStep & {
			height: 100%;
			@include ease(height, $durationBase, $easeBase);//, $durationBase);
		}
	}
}
