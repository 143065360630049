.block {
	&__title {
		@include font(baseMedium);
		color: $colorBase;
		position: relative;
		text-align: center;


		//VARIANTS

		//BLOCK SPECIFIC

		.block__headerLink &,
		.block--linked & {
			color: $colorHighlight;
		}

		.block--highlight-header & {
			@include font(titleMedium);
		}

		.block--teaser & {
			@include font(highlightTitle);
			color: $colorInverted;
			position: relative;
			overflow: hidden;

			&::after {
				@include ease(transform opacity width, $teaserUnderlineDuration);
				background-color: currentColor;
				bottom: $baseHalf;
				content: '';
				display: block;
				height: 2px;
				left: 0;
				position: absolute;
				transform: translateX(-100%);
				width: 100%;

				@include media($smallLayout) {
					transform: translateX(0);
					height: 3px;
					width: 0;
				}
				@include media($mediumLayout) {
					height: 4px;
				}
			}
		}


		@include onHover('.block--teaser .block__headerLink', ' &::after') {
			opacity: 1;
			width: 100%;
		}

		@include onHover('.block--teaser a.block__container', ' &::after') {
			opacity: 1;
			width: 100%;
		}

		@include onHover('.block--content-teaser .block__headerLink', ' &') {
			color: $colorHighlightLight;
		}

		@include onHover('.block--content-teaser.block--linked .block__container', ' &') {
			color: $colorHighlightLight;
		}

		.block--teaser.block--withLinks.js-intersected &::after,
		.block--teaser.block--linked.js-intersected &::after {
			transform: translateX(0);
		}


		.block--section-header & {
			@include font(highlightMedium);
			color: $colorBase;
			order: 2;
			position: relative;
			text-align: center;
		}

		.block--alternative-title & {
			@include font(titleMedium);
			text-transform: none;
		}

		.block__content > & {
			@include font(highlight);
			order: 0;
			text-transform: uppercase;
		}

		.block--text & {
			@include font(titleMedium);
			margin-top: $base1;

			@include media($smallLayout) {
				margin-top: $base2;
			}

			@include media($mediumLayout) {
				width: staticColumnSize(10, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(8, 12, $gutterLarge);
			}
		}

		.block--video &,
		.block--image & {
			@include ease(color, $hoverDuration);
			@include font(titleMedium);
			text-align: left;
			color: $colorBase;
			padding-top: $videoTitleSpacingSmall;
			margin-bottom: $base1;

			@include media($smallLayout) {
				width: 100%;
				padding-top: $videoTitleSpacingLarge;
			}
		}

		.block--content-teaser & {
			margin-bottom: $base1;

			@include media($smallLayout) {
				margin-bottom: $base2;
			}
		}

		.block--content-teaser.block--reverseTitleAndCategory & {
			margin-bottom: $baseHalf;

			@include media($smallLayout) {
				margin-bottom: $contentTeaserCategoryVerticalSpacing;
			}
		}
	}
}
