.blocks {
	position: relative;
	overflow: hidden;

	@include media($columnsBreakpoint) {
		// overflow: auto;
	}


	&.blocks--nested {
		@include ease(opacity visibility height padding-bottom, $durationBase, $easeBase);
		@include dynamicColumns($gutterSmall, $nestedBlockVerticalSpacingSmall);
		height: auto;
		overflow: hidden;

		@include media($smallLayout) {
			@include dynamicColumns($gutterMedium, $nestedBlockVerticalSpacingSmall);
		}

		@include media($largeLayout) {
			@include dynamicColumns($gutterLarge, $nestedBlockVerticalSpacingLarge);
		}

		.block--hAlignCenter & {
			justify-content: center;
		}
	}

	.block--vNestedBlockSpaceSmall &--nested {
		@include dynamicColumns($gutterSmall, $nestedBlockVerticalSpacingSmallAlternate);

		@include media($smallLayout) {
			@include dynamicColumns($gutterMedium, $nestedBlockVerticalSpacingSmallAlternate);
		}

		@include media($largeLayout) {
			@include dynamicColumns($gutterLarge, $nestedBlockVerticalSpacingLargeAlternate);
		}
	}


	&.blocks--nested.js-collapsed {
		opacity: 0;
		position: absolute;
		visibility: hidden;
		overflow: hidden;
		left: 0;
		right: 0;
		padding-left: $containerPaddingSmall;
		padding-right: $containerPaddingSmall;

		@include media($columnsBreakpoint) {
			padding-left: $containerPaddingLarge;
			padding-right: $containerPaddingLarge;
		}
	}


	// block__content has top margin when followed by block__header, since we have a case where we dont need the space when there is no block__content
	// but when the toggler button is used in the ugc text and toggled. The block__content still takes space eventhough the button is hidden.
	// extra space is visualy added up to the next block, which is the collapsable
	// to work around this corner case the margin of the block__content is substracted from the padding of the collapsable if the ugc content only has
	// a toggler button. class ".block--hasOnlyUgcToggler" is added. Can be seen in src/bin/site/snippets/blocks/text.php
	// Better solution is welcome ¯\_(ツ)_/¯

	// set padding for nested blocks
	.block--text + .block--collapsable &--nested {
		@include mediaMap($sizeVariants, blockVerticalSpacings, 'padding-top');
	}

	//substract header & content spacing from padding
	.block--text.block--hasOnlyUgcToggler + .block--collapsable &--nested {
		padding-top: $blockVerticalSpacingSmall - $blockHeaderTopSpacingSmall;

		@include media($mediumLayout) {
			padding-top: $blockVerticalSpacingLarge - $blockHeaderTopSpacingLarge;
		}
	}

	// the distance of the header & content element is an option for the text.
	// it shouldnt been used in the combination of text followed by layout, but better to cover that case also
	.block--text.block--hasOnlyUgcToggler.block--headerContentSpacingWide + .block--collapsable &--nested {
		padding-top: $blockVerticalSpacingSmall - $blockHeaderTopSpacingSmallAlternate;

		@include media($smallLayout) {
			padding-top: $blockVerticalSpacingLarge - $blockHeaderTopSpacingLargeAlternate;
		}
	}

	// set padding for nested blocks with vTopSpaceSmall
	.block--text + .block--collapsable.block--vNestedBlockSpaceSmall &--nested {
		@include mediaMap($sizeVariants, blockVerticalSpacingsAlternate, 'padding-top');
	}

	//substract header & content spacing from padding with vTopSpaceSmall
	.block--text.block--hasOnlyUgcToggler + .block--collapsable.block--vNestedBlockSpaceSmall &--nested {
		padding-top: $blockVerticalSpacingSmallAlternate - $blockHeaderTopSpacingSmall;

		@include media($smallLayout) {
			padding-top: $blockVerticalSpacingLargeAlternate - $blockHeaderTopSpacingLarge;
		}
	}

	// the distance of the header & content element is an option for the text with vTopSpaceSmall
	.block--text.block--hasOnlyUgcToggler.block--headerContentSpacingWide + .block--collapsable.block--vNestedBlockSpaceSmall &--nested {
		padding-top: $blockVerticalSpacingSmallAlternate - $blockHeaderTopSpacingSmallAlternate;

		@include media($smallLayout) {
			padding-top: $blockVerticalSpacingLargeAlternate - $blockHeaderTopSpacingLargeAlternate;
		}
	}
}
