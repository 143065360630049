.languageMenu {
	display: flex;

	.footerMenu & {
		@include font(footerMenuAdditionalLinks);
		width: staticColumnSize(4, 12, $gutterMedium);
	}

	.mainMenu & {
		@include font(detailMedium);
		padding-bottom: 0;
		text-transform: uppercase;
	}


	&__item {
		text-transform: uppercase;

		.footerMenu & {
			display: flex;
		}

		&:not(:last-child) {
			&::after {
				@include fadeOut($menuFadeDuration);
				content: ' / ';
				display: inline-block;
				margin-left: 0.4em;
				margin-right: 0.4em;

				.footerMenu & {
					@include fadeIn(0);
				}

				.mainMenu.js-active & {
					@include fadeIn();
				}
			}
		}

		&--current {
			pointer-events: none;
		}
	}


	&__link {
		@include ease(color, $hoverDuration);
		color: currentColor;
		text-decoration: none;

		@include onHover() {
			color: $colorHighlight;
		}

		&--active {
			text-decoration: underline;
		}
	}


	&__linkLabel {
		.languageMenu__item--current & {
			text-decoration: underline;
		}
	}
}
