.headerServiceMenu {
	@include font(headerServiceMenu);
	align-items: flex-end;
	display: none;
	pointer-events: all;
	text-transform: uppercase;

	@include media($mediumLayout) {
		@include ease(background-color, $duration3);
		@include fadeIn($durationBase, $easeBase);
		display: flex;

		.header.js-collapsed:not(.js-coveredByMenu) & {
			@include fadeOut($durationBase, $easeBase);
		}
	}

	[data-current-context="menu"] & {
		@include fadeOut($duration4);

		@include media($mediumLayout) {
			@include fadeIn();
		}
	}
}
