.newLanguageMenu {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-right: $vwS10;

	//>=768px
	@include media($mediumNewLayout) {
		display: flex;
		margin-right: $vwM20;
	}
	//>=1280px
	@include media($largeNewLayout) {
		margin-right: $vwL20;
	}
	//>=1680px
	@include media($xLargeNewLayout) {
		margin-right: $base2;
	}


	.newMainMenu__langNav & {
		margin-right: 0;
	}

	&__item {
		@include font(headerLinks);
		color: $colorSubTitle;
		display: inline-block;

		.header--darkColorVersion:not(.js-coveredByNewMenu) & {
			color: $newColorBorderDark;
		}

		.header--vividColorVersion:not(.js-coveredByNewMenu) & {
			color: $colorBgBase;
		}

		.newMainMenu__langNav & {
			@include font(newMainNavSecondLevel);
		}

		&:not(:last-child) {
			&::after {
				color: $colorHighlight;
				content: '|';
				display: inline-block;
				margin-right: $baseHalf;
				transform: rotate($newMenuLangLineRotationDeg);
				width: $baseHalf;

				.header--vividColorVersion:not(.js-coveredByNewMenu) & {
					color: $colorBase;
				}

				.newMainMenu__langNav & {
					margin-left: $base1;
				}
			}
		}

		&--current {
			color: $colorBase;
			pointer-events: none;

			.header--darkColorVersion:not(.js-coveredByNewMenu) & {
				color: $colorBgBase;
			}

			.header--vividColorVersion:not(.js-coveredByNewMenu) & {
				opacity: 0.7;
			}
		}
	}

	&__link {
		@include ease(color, $hoverDuration);
		color: currentColor;
		display: inline-block;
		text-decoration: none;

		@include onHover() {
			color: $colorHighlight;
		}

		&--active {
			color: $colorSubTitle;
		}

		.header--vividColorVersion:not(.js-coveredByNewMenu) & {
			@include onHover() {
				color: $colorBase;
			}
		}
	}

	&__linkLabel {
		&:first-child {
			margin-right: $newMenuLangMarginRight;
		}
	}
}
