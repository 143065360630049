.loader {
	@include fadeIn($durationBase, $easeBase);
	align-items: center;
	background-color: $colorBgBase;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;

	&.js-hidden {
		@include fadeOut($durationBase * 2, $easeBase, $durationBase * 2);
	}

	&__dot {
		@include ease(transform opacity, $durationBase, $easeBase);
		align-items: center;
		color: $colorHighlightLight;
		display: flex;
		flex-direction: row;
		text-decoration: none;
		position: relative;
		height: $base3;
		width: $base3;

		.loader.js-hidden & {
			transform: scale(0);
			opacity: 0;
		}
	}


	&__dotShape {
		@include ease(transform, $durationBase, $easeOutCubic);
		animation-delay: $durationBase;
		animation-duration: $durationBase * 3;
		animation-iteration-count: infinite;
		animation-name: loader-animation;
		background-color: $colorHighlightLight;
		border-radius: 50%;
		display: block;
		height: 100%;
		transform: scale(1);
		width: 100%;
	}
}
