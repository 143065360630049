.block {
	&__main {
		@include app-container-wide();

		.blocks--nested & {
			@include app-undoContainer();
		}


		// VARIANTS
		.block--imgOrientationPortrait & {
			@include media($smallLayout) {
				width: staticColumnSize(1, 2, 0);
			}
		}

		// BLOCK SPECIFIC

		.block__cover + & {
			margin-top: $base2;

			@include media($smallLayout) {
				margin-top: $base3;
			}
		}


		.blocks:not(.blocks--nested) > .block--content-teaser & {
			@include media($smallLayout) {
				@include app-container-wide();
				background-color: $colorBgBase;
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
				padding-top: $figureTopPadding;
				position: relative;
				width: staticColumnSize(11, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				margin-left: 0;
				max-width: auto;
				padding-left: 0;
				padding-right: $gutterMedium;
				width: staticColumnSize(6, 12, $gutterMedium, $gutterMedium);
			}

			@include media($largeLayout) {
				padding-right: $gutterLarge;
				width: staticColumnSize(6, 12, $gutterLarge, $gutterLarge);
			}
		}


		.block--highlight-header & {
			@include app-container-wide();
			order: 1;
			display: flex;
			flex-direction: column;

			@include media($smallLayout) {
				@include app-undoContainer();
			}


			@include media($mediumLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
				justify-content: center;
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 0;
				margin-right: $gutterMedium;
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
				margin-right: $gutterLarge;
			}
		}

		.block--highlight-header .block__cover + & {
			margin-bottom: $base2;

			@include media($smallLayout) {
				margin-bottom: $base5;
			}
		}

		.blocks:not(.blocks--nested) > .block--image & {
			@include app-container-wide();
			display: flex;
			align-items: center;
			flex-flow: column;
			position: relative;
		}


		.blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
			@include app-undoContainer();

			@include media($mediumLayout) {
				@include app-container-wide();
			}
		}


		.block--layouthSizeDefault .block--image & {
			@include app-undoContainer();
		}


		.blocks:not(.blocks--nested) > .block--teaser & {
			@include app-container();
			@include media($jigsawLayout) {
				// @include app-undoContainer();
				@include app-container-wide();
			}
		}

		.block--separatorVisible & {
			margin-top: $base3;

			@include media($mediumLayout) {
				margin-top: $base5;
			}
		}


		.block--video & {
			display: flex;
			flex-flow: column;
		}

		.block--newBlock & {
			@include app-undoContainer();
			@include app-newcontainer();
		}

		.block--fullWidth & {
			@include app-undoContainer();
			width: 100%;
		}

		.blocks:not(.blocks--nested) > .block--video.block--videoTypeContent.block--hSizeDefault & {
			position: relative;
			@include app-undoContainer();

			@include media($mediumLayout) {
				@include app-container-wide();
			}
		}

		.blocks:not(.blocks--nested) > .block--video.block--hSizeFull &,
		.blocks:not(.blocks--nested) > .block--video.block--videoTypeHighlight & {
			position: relative;
			@include app-undoContainer();
			padding-left: $base2;
			padding-right: $base2;

			@include media($smallLayout) {
				padding-left: $containerPaddingSmall;
				padding-right: $containerPaddingSmall;
			}

			@include media($mediumLayout) {
				@include app-container-wide();
			}
		}

	}
}
