.block {
	&__summary {
		margin-top: 0;
		position: relative;
		text-align: center;

		.block__category + &:not(:only-child) {
			margin-top: $blockHeaderContentSpacingSmall;

			@include media($smallLayout) {
				margin-top: $blockHeaderContentSpacingLarge;
			}
		}

		.block--text & {
			@include media($mediumLayout) {
				width: staticColumnSize(10, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(8, 12, $gutterLarge);
			}
		}
	}
}
