.block {
	&__cover {
		@mixin coverMargin() {
			@include media($largeLayout) {
				padding-left: $base * 20;
				padding-right: $base * 20;
			}
		}

		@include conditionalContainer();
		@include coverMargin();

		// padding-bottom: $base3;
		// @include media($smallLayout) {
		// 	padding-bottom: $base5;
		// }

		// .block--imgOrientationPortrait & {
		// 	@include media($smallLayout) {
		// 		padding-left: 0;
		// 		padding-right: $base5;
		// 		padding-bottom: 0;
		// 		width: staticColumnSize(1, 2, 0);
		// 	}
		// }

		// TEXT BLOCK VARIANTS
		.blocks:not(.blocks--nested) > .block--text & {
			@include app-undoContainer();

			@include media($mediumLayout) {
				@include app-container-wide();
			}

			// @include coverMargin();
		}



		.block--group & {
			@include app-undoContainer();
		}

		.block--highlight-header & {
			@include app-container-wide();
			order: 2;

			@include media($smallLayout) {
				@include app-undoContainer();
				width: staticColumnSize(8, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
				margin: 0;
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
			}

			// @include coverMargin();
		}

		.block--image & {
			@include app-container-wide();

		}

		.blocks:not(.blocks--nested) > .block--text.block--imgWidthDefault > .block__container > & {
			width: 100%;
			@include app-container-wide();
			display: flex;
			justify-content: center;
			align-items: flex-start;
		}
	}
}
