.newButton {
	&:disabled,
	&.js-disabled {
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}

	&--newsletterSubmit {
		@include ease(background-color color, $hoverDuration);
		@include font(newCategory);
		background-color: transparent;
		border: solid $inputBorderSize $newColorFormElemBorder;
		color: $colorBgBase;
		cursor: pointer;
		margin-top: $vwS20;
		order: 1;
		padding: $newFormElemLargeSpacing $base1;
		text-align: center;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: 0;
			order: 0;
			width: staticColumnSize(3, 8, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(2, 8, $newGutterLarge);
		}

		@include onHover() {
			background-color: $colorBgBase;
			color: $colorHighlight;
		}
	}
}
