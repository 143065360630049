.block {
	&__category {
		@include font(highlightMedium);
		//padding-top: $base6;
		position: relative;
		text-align: center;

		.block--imgOrientationPortrait & {
			@include media($smallLayout) {
				padding-top: $base6;
				border-top: 1px solid $colorBase;
			}
		}

		.block__cover + .block__main & {
			@include media($smallLayout) {
				margin-top: $base2;
			}
		}

		.block--content-teaser & {
			margin-bottom: $baseHalf;

			@include media($smallLayout) {
				margin-bottom: $contentTeaserCategoryVerticalSpacing;
			}
		}

		.block--content-teaser.block--reverseTitleAndCategory & {
			margin-bottom: $base1;

			@include media($smallLayout) {
				margin-bottom: $base2;
			}
		}
	}
}
