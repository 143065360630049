.block {
	&__headerLink {
		color: currentColor;
		text-decoration: none;

		.block--teaser & {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		.block--content-teaser.block--reverseTitleAndCategory & {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}
