.menu {
	.mainMenu & {
		@include app-container-wide();
		padding-bottom: $base5;
		padding-top: $base1;
		display: flex;
		align-items: center;
		flex-direction: column;

		@include media($smallLayout) {
			padding-bottom: $base5;
			padding-top: $menuTopSpacing;
		}

		@include media($mediumLayout) {
			background-color: transparent;
			padding-top: 0;
			transform-origin: top;
			transform-style: preserve-3d;
			transition:
				background-color $durationBase,
				transform $durationBase,
				visibility $durationBase,
				opacity $durationBase;
			transform: translateY(- $base * 7.4) rotateX(- $menuRotationDeg);
		}
	}

	.mainMenu.js-firstActiveStep & {
		background-color: $colorBgModal;
		@include media($mediumLayout) {
			transition:
				background-color $durationBase,
				transform $durationBase,
				visibility $durationBase,
				opacity $durationBase;
		}
	}

	.mainMenu.js-secondActiveStep & {
		@include media($mediumLayout) {
			transform: translateY(0) rotateX(0);
		}
	}



	&__item {
		.footerMenu .menu > .menu__items > & {
			margin-bottom: $base;
			width: dynamicColumnSize(1, 2, $gutterMedium);
			// width: staticColumnSize(1, 2, $gutterMedium);

			@include media($mediumLayout) {
				width: dynamicColumnSize(1, 4, $gutterLarge);
			}

			&[data-page-title="lang"] {
				@include font(footerMenuAdditionalLinks);
				align-self: center;
				order: 2;
			}

			&[data-page-title="social"] {
				order: 3;
			}
		}

		.footerMenu--social .menu > .menu__items > & {
			width: auto;

			@include media($mediumLayout) {
				width: auto;
			}
		}

		.footerMenu .menu [data-page-title="social"] & {
			margin-right: $base2;

			&:last-child {
				margin-right: 0;
			}
		}


		//header service menu
		.headerServiceMenu & + & {
			margin-left: $base2;

			@include media($mediumLayout) {
				margin-left: $base3;
			}
		}


		//main menu
		.mainMenu & {
			//overflow: hidden;
			text-align: left;

			//opacity: 0;

			&.js-active {
				color: $colorHighlight;
			}
		}

		.mainMenu.js-active & {
			//opacity: 1;
		}

		//menu titles
		.mainMenu .menu__items--menuTitles > & {
			@include media($smallLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(3, 12, $gutterLarge);
			}
		}

		.mainMenu .menu__items--menuTitles > & + & {
			margin-top: $base3;

			@include media($smallLayout) {
				margin-top: 0;
			}
		}

		.mainMenu .menu__items--menuTitles > &:nth-child(n+3) {
			@include media($smallLayout) {
				margin-top: $base3;
			}

			@include media($mediumLayout) {
				margin-top: 0;
			}
		}

		.mainMenu .menu__items--menuTitles > &:nth-child(even) {
			@include media($smallLayout) {
				margin-left: $gutterMedium;
			}

			@include media($mediumLayout) {
				margin-left: 0;
			}
		}

		.mainMenu .menu__items--menuTitles > &:nth-child(n+2) {
			@include media($mediumLayout) {
				margin-left: $gutterLarge;
			}
		}


		// nested
		.mainMenu .menu__items--nested > & {
			@include font(baseSmallItalicAlternate);
			text-transform: none;
			opacity: 0;
			@include ease(opacity, $durationBase);
		}

		.mainMenu.js-active .menu__items--nested > & {
			opacity: 1;
		}

		.mainMenu .menu__items--nested & + & {
			margin-top: $baseHalf;
		}


		// sub menu
		.mainMenu .menu__items--submenu & {
			@include font(detailMedium);
			display: inherit;
			width: staticColumnSize(1, 2, $gutterSmall);

			@include media($smallLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(3, 12, $gutterLarge);
			}
		}

		.mainMenu .menu__items--submenu > &:nth-child(even) {
			@include media($smallLayout) {
				margin-left: $gutterMedium;
			}

			@include media($mediumLayout) {
				margin-left: 0;
			}
		}


		.mainMenu .menu__items--submenu &:nth-child(n+3) {
			margin-top: $base2;

			@include media($smallLayout) {
				margin-top: $base1;
			}

			@include media($mediumLayout) {
				margin-top: 0;
			}
		}


		.mainMenu .menu__items--submenu &:nth-child(n+5) {
			@include media($mediumLayout) {
				margin-top: $base1;
			}
		}

		.mainMenu .menu__items--submenu &:not(:nth-child(4)) {
			@include media($mediumLayout) {
				margin-right: $gutterLarge;
			}
		}
	}


	&__items {
		.footerMenu .menu > & {
			@include dynamicColumns($base3);
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;

			@include media($mediumLayout) {
				@include dynamicColumns($gutterLarge);
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;
			}
		}


		.footerMenu--social .menu > & {
			@include dynamicColumns($base2);
			justify-content: center;

			@include media($mediumLayout) {
				@include dynamicColumns($base2);
				justify-content: center;
			}
		}


		.footerMenu .menu [data-page-title="social"] &--nested {
			display: flex;
			flex-direction: row;
			margin-bottom: 0;
		}

		.footerMenu &--nested {
			@include font(footerMenu2ndLevel);
			margin-top: $base;
			font-style: italic;

			@include media($smallLayout) {
				margin-bottom: $base2;
			}

			@include media($mediumLayout) {
				margin-bottom: $base4;
			}
		}

		//header Menu
		.headerMenu & {
			display: flex;
			justify-content: space-between;
		}


		//header service menu
		.headerServiceMenu & {
			display: flex;
		}

		// main Menu
		.mainMenu & {
			@include media($mediumLayout) {
				display: flex;
			}
		}

		.mainMenu &--nested {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: flex-start;

			@include media($mediumLayout) {
				flex-direction: column;
				align-items: flex-start;
				margin-top: $base2;
				//visibility: hidden;
			}
		}


		.mainMenu.js-firstActiveStep &--nested {
			@include media($mediumLayout) {
				visibility: inherit;
			}
		}

		// menu titles
		.mainMenu &--menuTitles {

			@include media($smallLayout) {
				display: flex;
				flex-wrap: wrap;
			}

			@include media($mediumLayout) {
				@include ease(width transform, $durationBase);
				flex-wrap: nowrap;
				transform-origin: top;
				transform-style: preserve-3d;
				transform: rotateX($menuRotationDeg);
				width: 25%;
			}
		}


		.mainMenu.js-secondActiveStep &--menuTitles {
			width: 100%;
			pointer-events: all;
			transform: rotateX(0);
		}



		.mainMenu &--submenu {
			border-top: $colorBase 1px solid;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: $base3;
			padding-top: $base3;
			pointer-events: all;
			width: 100%;

			@include media($smallLayout) {
				padding-top: $base5;
				margin-top: $base5;
			}

			@include media($mediumLayout) {
				//visibility: hidden;
				border-top: none;
				@include fadeOut($durationBase);
			}
		}


		.mainMenu.js-firstActiveStep &--submenu {
			@include media($mediumLayout) {
				//visibility: inherit;
				@include fadeIn($durationBase, $easeBase, $durationFast * 1.7);
			}
		}

		.mainMenu.js-active &--submenu {
			@include media($mediumLayout) {
				border-top: $colorBase 1px solid;
			}
		}
	}


	&__link {
		color: currentColor;
		text-decoration: none;

		.footerMenu .menu > .menu__items > .menu__item > & {
			@include font(footerMenuAdditionalLinks);
			font-family: $fontFamilyAlt;
			text-transform: uppercase;
		}

		.footerMenu [data-page-title="social"] &,
		.footerMenu--social & {
			@include onHover {
				color: $colorHighlight;
			}
		}
	}


	&__linkLabel {
		.footerMenu & {
			@include ease(color, $hoverDuration, $easeBase);

			[data-type="svg"] {
				width: $base * 3.5;
			}
		}

		.mainMenu & {
			white-space: nowrap;
		}

		.mainMenu .menu__items--submenu & {
			text-transform: uppercase;
		}
	}

	&__sectionTitle {
		@include font(baseSmallMedium);
		margin-bottom: $baseHalf;
		pointer-events: all;
		text-align: left;
		text-transform: lowercase;

		@include media($smallLayout) {
			margin-bottom: $base1;
		}

		@include media($mediumLayout) {
			margin-bottom: 0;
		}

		.footerMenu [data-page-title="social"] & {
			display: none;
		}
	}
}
