.newMainNavs {
	background-color: $colorBgBase;
	display: none;
	min-height: 100vh;
	opacity: 0;
	top: 0;
	width: 100%;

	&.js-firstActiveStep {
		display: block;
		opacity: 1;
		position: fixed;
		z-index: 50;
		top: 0;
		bottom: 0;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	&__container {
		@include app-newcontainer();
		display: block;
		height: 100%;
		min-height: 100vh;

		//>=1280px
		@include media($largeNewLayout) {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&__containerWrapper {
		@include staticColumns();
		display: block;
		height: 100%;
		margin: {
			left: 0;
			top: $vwS50 * 2;
		}
		min-height: 100vh;
		//transition: all ease 0.7s;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 0;
			width: staticColumnSize(10, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			flex-direction: row;
			height: auto;
			justify-content: flex-start;
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
			min-height: auto;
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
	}

	&__logo {
		@include app-link();
		display: block;
		position: absolute;
		top: $base1;
		width: staticColumnSize(3, 6, $newGutterSmall);
		opacity: 0;

		.body:not(.js-scrolledDown) & {
			//opacity: 1;
		}

		.body.js-scrolledDown & {
			transform: translateY(-$base * 1.5);
		}

		.newMainNavs.js-secondActiveStep & {
			@include ease(opacity transform, $durationBase, $easeBase);
			opacity: 1;
			transform: translateY(0);
		}

		@include media($mediumNewLayout) {
			top: $base3;
			width: staticColumnSize(3, 10, $newGutterSmall);
		}

		@include media($largeNewLayout) {
			position: relative;
			top: $base1;
			width: staticColumnSize(3, 10, $newGutterLarge);
			transform: translateY(-$base * 1.5);
		}
	}

	&__navsWrapper {
		margin-left: 0;
		padding-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 0;
		}
		//>=1280px
		@include media($largeNewLayout) {
			display: block;
			margin-left: $base2;
			padding-left: 0;
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
	}
}
