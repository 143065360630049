.headerButtonMenu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	pointer-events: all;
	position: absolute;
	right: 0;
	top: 0;

	@include media($mediumLayout) {
		transform: translateX(100%) translateX($base * 2.4);
	}

	@include media($largeLayout) {
		transform: translateX(100%) translateX($base * 3.5);
	}


	&__item {
		line-height: 0;

		&--search {
			visibility: hidden;
		}
	}
}
