.newSocialMediaMenu {
	margin-bottom: -$vwS10;

	//>=768px
	@include media($mediumNewLayout) {
		margin-bottom: 0;
	}

	&__link,
	&__item,
	&__items {
		@include staticColumns();
	}

	&__item {
		height: 100%;
		margin-bottom: $vwS10;
		width: staticColumnSize(3, 6, $newGutterSmall);

		//>=768px
		@include media($mediumNewLayout) {
			height: $newSocialMenuItemHeight;
			margin-bottom: 0;
			width: auto;
		}
	}

	&__items {
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-wrap: nowrap;
			justify-content: flex-start;
		}
	}

	&__link {
		@include new-newsletter-media-link();
		align-items: center;
		border-right: none;
		cursor: pointer;
		margin-right: 0;
		padding-right: 0;

		//>=768px
		@include media($mediumNewLayout) {
			border-right: $inputBorderSize solid $colorBgBase;
			height: $newSocialMenuItemHeight;
			margin-right: $vwM20;
			padding-right: $vwM20;
			width: auto;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-right: $vwL20;
			padding-right: $vwL20;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-right: $base2;
			padding-right: $base2;
		}

		.newSocialMediaMenu__item:last-child & {
			border-right: none;
		}
	}

	&__linkIcon {
		display: inline-block;
		height: $newSocialMenuLinkHeight;
		margin-right: $base1;

		[data-type='svg'] {
			height: 100%;
		}
	}

	&__linkText {
		@include font(newSocialMediaLink);
	}
}
