.captionModal {
	@include app-container();
	@include fadeOut($duration4);
	@include ease(opacity visibility transform, $duration4);
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	bottom: auto;
	transform: translateY(50px);
	z-index: 3;
	background-color: $colorBgInput;

	body[data-current-context="menu"] & {
		display: none;
	}

	body[data-current-context="newMenu"] & {
		display: none;
	}

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility, transform;
		top: auto;
		bottom: 0;
	}

	&.js-duringActive {
		@include fadeIn();
		top: auto;
		bottom: 0;
		transform: translateY(0);
	}
	&.js-active {
	}

	&__content {
		@include app-container();
		padding-bottom: $blockVerticalSpacingSmall;
		padding-top: $firstBlockTopSpacingSmall;

		@include media($smallLayout) {
			padding-top: $firstBlockTopSpacingLarge;
			padding-bottom: $blockVerticalSpacingLarge;
		}
	}

	&__close {
		cursor: pointer;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 20px;
		right: 20px;
		[data-type="svg"] {
			width: 100%;
			height: auto;
		}
	}
	&__container {
		color: $colorBase;
		margin-bottom: $base2;
		margin-top: $base6;
		position: relative;

		@include media($largeLayout) {
			margin-bottom: $base3;
			margin-top: $base6;
		}
	}
}
