.newMainMenu {
	&__additionalNav {
		@include staticColumns();
		align-items: center;
		justify-content: flex-start;
		margin: {
			bottom: $vwS30;
			top: $vwS30;
		}
		opacity: 0;

		.newMainNavs.js-secondActiveStep & {
			@include ease(opacity, $durationBase, $easeBase, $durationDelayBetweenElements * 8);
			opacity: 1;
		}

		//>=768px
		@include media($mediumNewLayout) {
			bottom: 0;
			display: block;
			margin: {
				bottom: 0;
				top: 0;
			}
			position: absolute;
		}

		//>=1280px
		@include media($largeNewLayout) {
			position: relative;
		}
	}

	&__desktopMain {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(5, 10, $newGutterSmall);
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 7, $newGutterLarge);
		}
	}

	&__desktopSub {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(5, 10, $newGutterSmall);
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(4, 7, $newGutterLarge);
		}
	}

	&__extraNav {
		margin-top: 0;

		//>=768px
		@include media($mediumNewLayout) {
			bottom: $base3;
			position: relative;
		}

		//>=1280px
		@include media($largeNewLayout) {
			bottom: 0;
			margin-top: $base5;
		}
	}

	&__items {
		&--nested {
			margin: {
				bottom: $vwS15;
				top: $vwS30;
			}

			//>=768px
			@include media($mediumNewLayout) {
				margin: {
					bottom: 0;
					top: 0;
				}
			}

			.newMainMenu__extraNav & {
				@include staticColumns();
				justify-content: flex-start;
				margin: {
					bottom: 0;
					top: 0;
				}
			}
		}

		.newMainMenu__extraNav & {
			//>=768px
			@include media($mediumNewLayout) {
				@include staticColumns();
				justify-content: flex-start;
			}
		}
	}

	&__langNav {
		display: block;

		//>=768px
		@include media($mediumNewLayout) {
			display: none;
		}
	}

	&__mainNav {
		width: 100%;

		&--mobile {
			@include media($mediumNewLayout) {
				display: none;
			}
		}

		&--desktop {
			display: none;

			//>=768px
			@include media($mediumNewLayout) {
				@include staticColumns();
				justify-content: space-between;
				min-height: $base * 32;
				position: relative;
				width: 100%;
			}
		}
	}
}
