.block {
	&__subtitle {
		@include font(baseSmall);
		position: relative;
		text-align: center;


		// padding-bottom: $base;

		// * + & {
		// 	margin-top: $base;
		// }

		// @include media($smallLayout) {
		// 	margin-top: 0;
		// 	padding-bottom: $base2;
		// }

		// .block--imgOrientationLandscape &,
		// .block--imgOrientationPortrait & {
		// 	padding-bottom: $base3;
		// 	@include media($smallLayout) {
		// 		padding-bottom: $base4;
		// 	}
		// }

		.block--highlight-header & {
			@include font(titleMedium);
		}

		.block--highlight-header .block__title + & {
			@include font(highlight);
			margin-top: $base1;
		}

		.block--text & {
			@include font(highlightMedium);
			margin-top: $base * 1.5;

			a {
				color: $colorBase;
			}

			@include media($smallLayout) {
				margin-top: $base2;
			}

			@include media($mediumLayout) {
				margin-top: $base2;
			}
		}

		.block--video &,
		.block--image & {
			@include font(baseSmallItalic);
			color: $colorBase;
			padding-bottom: 0;
			text-align: left;
		}
	}
}
