.block {

	--color: #{$colorBase};
	--highlight-color: #{$colorHighlight};
	--background-color: #{$colorBgBase};
	--border-color: #{$newColorBorderBase};
	--highlightComplement-color: #{$colorBgBase};

	&.block--lightColorVersion {
		--background-color: #{$lightColorBg};
		--highlightComplement-color: #{$lightColorBg};
	}
	&.block--vividColorVersion {
		--color: #{$colorBgBase};
		--highlight-color: #{$colorBase};
		--background-color: #{$colorHighlight};
		--border-color: #{$colorBgBase};
		--highlightComplement-color: #{$colorBgBase};
	}
	&.block--darkColorVersion {
		--color: #{$colorBgBase};
		--background-color: #{$colorBase};
		--highlightComplement-color: #{$colorBgBase};
	}


	display: flex;
	flex-direction: column;
	position: relative;


	&.js-covered {
		@include app-covered();
	}

	// GENERIC

	// all blocks, not nested, that followed by another block have top margin
	// for nested block vertical space see blocks.scss
	.blocks:not(.blocks--nested) > & + & {
		@include mediaMap($sizeVariants, blockVerticalSpacings);
	}

	.blocks:not(.blocks--nested) > & + &--vTopSpaceMedium {
		@include mediaMap($sizeVariants, blockVerticalSpacingsAlternate);
	}

	// same sizes but different breakpoint
	.blocks:not(.blocks--nested) > & + &--vTopSpaceMediumAlt {
		@include mediaMap($sizeVariants, blockVerticalSpacingsAlternate3);
	}

	.blocks:not(.blocks--nested) > & + &--vTopSpaceSmall {
		@include mediaMap($sizeVariants, blockVerticalSpacingsAlternate2);
	}

	.blocks:not(.blocks--nested) > &--withLinks + &__additionalContent + &--withLinks {
		@include mediaMap($sizeVariants, verticalSpacingsWithLinks);
	}

	.blocks:not(.blocks--nested) > &:first-child {
		@include mediaMap($sizeVariants, firstBlockVerticalSpacings);
	}

	// more top space for when the section header is first block and does not have the separator visible
	.blocks:not(.blocks--nested) > &--section-header.block--separatorHidden:first-child {
		padding-top: $base5;
	}

	// alternate top spacing for section header
	.blocks:not(.blocks--nested) > & + &--section-header {
		@include mediaMap($sizeVariants, blockVerticalSpacingsSeparator);
	}

	.blocks:not(.blocks--nested) > & + &--newBlock,
	.blocks:not(.blocks--nested) > & + &--fullWidth,
	.blocks:not(.blocks--nested) > &--fullWidth:first-child,
	.blocks:not(.blocks--nested) > &--newBlock:first-child {
		margin: {
			bottom: $base0;
			top: $base0;
		}
	}

	// nested block have there spacings defined by the dynamicColumnsLayout
	.blocks--nested > & {
		@include dynamicColumnsModifiers($gutterSmall);

		@include media($smallLayout) {
			@include dynamicColumnsModifiers($gutterMedium);
		}

		@include media($largeLayout) {
			@include dynamicColumnsModifiers($gutterLarge);
		}
	}

	.block--layout .blocks--layoutTypeSingleColumn > & {
		flex-shrink: 1;
	}

	.block--layout .blocks--layoutTypeSingleColumn > &.block--hSizeDefault {
		flex-shrink: 1;
		width: dynamicColumnSize(2, 2, $gutterSmall);

		@include media($mediumLayout) {
			width: dynamicColumnSize(10, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: dynamicColumnSize(8, 12, $gutterLarge);
		}
	}

	// TODO: general spacing, can be different with the two column hSizeDefault situation
	.block--layout .blocks--layoutTypeTwoColumns > & {
		flex-shrink: 1;
		width: dynamicColumnSize(2, 2, $gutterSmall);

		@include media($mediumLayout) {
			width: dynamicColumnSize(6, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: dynamicColumnSize(6, 12, $gutterLarge);
		}
	}

	.blocks--nested.blocks--layoutTypeTwoColumns &--hSizeDefault {
		width: dynamicColumnSize(1, 2, $gutterSmall);

		@include media($smallLayout) {
			width: dynamicColumnSize(6, 12, $gutterMedium);
		}

		@include media($mediumLayout) {
			width: dynamicColumnSize(5, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: dynamicColumnSize(4, 12, $gutterLarge);
		}
	}

	// VARIANTES

	&--colorSchemeInverted {
		background-color: $colorBgInverted;
		color: $colorInverted;
	}

	&--colorSchemeAlternative {
		background-color: $colorBgAlternative;
		color: $colorAlternative;
	}

	&--content-teaser {
		flex-direction: column;
	}

	&--hAlignBegin {
		text-align: left;
	}

	&--hAlignEnd {
		text-align: right;
	}

	// &--hAlignCenter {
	// 	text-align: center;
	// }

	&--hAlignJustified {
		text-align: justify;
	}

	&--image {
		.blocks--nested & {
			margin-bottom: 0;

			@include media($smallLayout) {
				margin-bottom: 0;
			}
		}
	}

	&--darkColorVersion {
		background-color: $colorBgInverted;
		color: $colorBgBase;
	}

	&--lightColorVersion {
		background-color: $colorBgInput;
		color: $colorBgInverted;
	}

	&--veryLightColorVersion {
		background-color: $colorBgBase;
		color: $colorBgInverted;
	}

	&--vividColorVersion {
		background-color: $colorAlternative;
		color: $colorBgBase;
	}

	&--lightColorVersion,
	&--veryLightColorVersion,
	&--darkColorVersion {
		&.block--newWideHeadline {
			color: $colorHighlight;
		}
	}

	&--darkColorVersion,
	&--lightColorVersion,
	&--veryLightColorVersion,
	&--vividColorVersion {
		padding: {
			bottom: $vwS50;
			top: $vwS50;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM100;
				top: $vwM100;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL150;
				top: $vwL150;
			}
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				bottom: $base150;
				top: $base150;
			}
		}
	}

	&--newTitle,
	&--newSubNavigation {
		padding {
			bottom: $vwS30;
		}

		//768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM30;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL50;
			}
		}
		@include media($xLargeNewLayout) {
			padding: {
				bottom: $base5;
			}
		}
	}

	&--topNoSpace {
		padding-top: 0;
	}

	&--topSmallSpace {
		padding-top: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			padding-top: $vwM30;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-top: $vwL50;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-top: $base5;
		}
	}

	&--topMediumSpace {
		padding-top: $vwS30;

		//>=768px
		@include media($mediumNewLayout) {
			padding-top: $vwM50;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-top: $vwL100;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-top: $base10;
		}
	}

	&--topLargeSpace {
		padding-top: $vwS50;

		//>=768px
		@include media($mediumNewLayout) {
			padding-top: $vwM100;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-top: $vwL150;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-top: $base150;
		}
	}

	&--bottomNoSpace {
		padding-bottom: 0;
	}

	&--bottomSmallSpace {
		padding-bottom: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			padding-bottom: $vwM30;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-bottom: $vwL50;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-bottom: $base5;
		}
	}

	&--bottomMediumSpace {
		padding-bottom: $vwS30;

		//>=768px
		@include media($mediumNewLayout) {
			padding-bottom: $vwM50;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-bottom: $vwL100;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-bottom: $base10;
		}
	}

	&--bottomLargeSpace {
		padding-bottom: $vwS50;

		//>=768px
		@include media($mediumNewLayout) {
			padding-bottom: $vwM100;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-bottom: $vwL150;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-bottom: $base150;
		}
	}


	&--slideshow {
		overflow: hidden;
	}

	&--section-header {
		@include separator('&','top');

		.blocks:not(.blocks--nested) > .block--videoTypeHighlight + & {
			margin-top: 0;

			@include media($smallLayout) {
				margin-top: $blockVerticalSpacingLargeAlternate;
			}
		}
	}

	&--teaser {
		align-items: center;

		.blocks:not(.blocks--nested) > & + & {
			@include mediaMap($sizeVariants, teaserVerticalSpacings);
		}

		&:last-of-type {
			//margin-bottom: $teaserVerticalSpacingSmall;

			@include media($mediumLayout) {
				//margin-bottom: $teaserVerticalSpacingLarge;
			}
		}

		&.block--colorSchemeDefault {
			background-color: $colorBgBase;
		}

		&.block--minHeightFixed {
			height: $teaserMinHeightIeFix; // minor than min-height, to allow align-items center to work on IE11
			min-height: $teaserMinHeight;
		}
	}

	&--video {
		.blocks:not(.blocks--nested) > &.block--videoTypeHighlight {
			margin-bottom: $base;

			@include media($smallLayout) {
				margin-bottom: initial;
			}

			@include media($mediumLayout) {
				margin-bottom: $base9;
			}

			@include media($largeLayout) {
				margin-bottom: $base10;
			}
		}
	}

	// BLOCK SPECIFIC

	.blocks:not(.blocks--nested) > &--breadcrumb-links {
		z-index: 2;
	}

	.blocks:not(.blocks--nested) > &--content-teaser {
		@include media($mediumLayout) {
			@include app-container-wide();
		}
	}


	// remove block top margin for collapsed blocks, the should have there spacing in the collapsable area as padding
	.blocks:not(.blocks--nested) > & + &--collapsable {
		margin-top: 0;
	}

	.blocks:not(.blocks--nested) > &--slideshow + & {
		margin-top: 0;
	}


	&--separator {
		margin: 0 !important;
		padding: 0 !important;
	}
}

//BLOCK ELEMENT IMPORTS

@import "./block-elements/category";
@import "./block-elements/container";
@import "./block-elements/content";
@import "./block-elements/cover";
@import "./block-elements/figure";
@import "./block-elements/header-link";
@import "./block-elements/header";
@import "./block-elements/main";
@import "./block-elements/subtitle";
@import "./block-elements/summary";
@import "./block-elements/title";
@import "./block-elements/figure-container";
@import "./block-elements/additional-content";
