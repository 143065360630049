@use "sass:math";

.image {
	@include ease(opacity, $fadeDuration);
	max-width: 100%;
	position: relative;

	// if problems of the anchor scroll position occurs, check if an element needs to be excluded from the minAscpectRatio
	.block:not(.block--content-teaser):not(.block--newProfileHero)
		&:not(&--teamContentImage) {
		@include ifSupportCustomProperties() {
			@include minAspectRatio(var(--heightRatioPerc));
			flex-wrap: wrap;
		}
	}

	&::after {
		@include ease(visibility opacity, $fadeDuration);
		animation: image $spinningIconDuration linear infinite;
		border-radius: 50%;
		border-right: $spinningIconBorderSize solid transparent;
		border-top: $spinningIconBorderSize solid $colorBase;
		content: "";
		display: block;
		height: $spinningIconSize;
		left: 50%;
		position: absolute;
		top: 50%;
		transform-origin: center;
		width: $spinningIconSize;
	}

	&.js-loaded::after {
		@include fadeOut();
		animation: image $spinningIconDuration linear forwards;
	}

	html:not(.mobile) .block--linked .block__container:hover &,
	html:not(.mobile) .block--linked .block__figure:hover &,
	html:not(.mobile) .block--withLinks .block__figure:hover &,
	.block--linked .block__container:active &,
	.block--linked .block__figure:active &,
	.block--withLinks .block__figure:active & {
		opacity: $hoverBgOpacity;
	}
	html:not(.mobile)
		.block--decorationPattern1.block--withLinks
		.block__figure:hover
		&,
	html:not(.mobile)
		.block--decorationPattern2.block--withLinks
		.block__figure:hover
		&,
	html:not(.mobile)
		.block--decorationPattern3.block--withLinks
		.block__figure:hover
		&,
	html:not(.mobile)
		.block--decorationPattern4.block--withLinks
		.block__figure:hover
		& {
		opacity: initial;
	}

	&--bg {
		height: 100%;
		user-select: none;
		width: 100%;

		.block__figure--video & {
			@include ease(opacity visibility, $duration2);
		}

		.block__figure--video.js-started & {
			@include fadeOut();
		}

		.block--teaser & {
			overflow: hidden;
		}
	}

	&--teamContentImage {
		@include media($smallLayout) {
			margin-right: $base3;
			display: block;
			width: 40%;
			flex-shrink: 0;
		}

		@include media($mediumLayout) {
			margin-right: $teamSpace;
			display: block;
			width: 40%;
			flex-shrink: 0;
		}

		& + * {
			@include media($smallLayout) {
				flex-grow: 1;
			}
		}
	}

	.blocks--layoutTypeTwoColumns
		.block:not(.block--content-teaser)
		.ugc
		&--medium:not(&--teamContentImage):not(&--slide) {
		display: inline-flex;
		vertical-align: middle;
		width: staticColumnSize(2, 6, $gutterSmall);

		@include media($smallLayout) {
			width: staticColumnSize(3, 12, $gutterMedium);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(2, 6, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(2, 6, $gutterLarge);
		}
	}

	.blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
		width: 100%;
	}

	.blocks:not(.blocks--nested)
		> .block--text.block--imgWidthDefault
		> .block__container
		> .block__cover
		> & {
		width: 100%;

		@include media($mediumLayout) {
			// padding-left: 0;
			// padding-right: 0;
			width: staticColumnSize(10, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(8, 12, $gutterLarge);
		}
	}

	.block--content-teaser & {
		@include aspectRatio($videoRatio);
	}

	.preview & {
		.block--colSpan12 & {
			@include media($columnsBreakpoint) {
				width: staticColumnSize(2, 3, $gutterMedium);
			}
		}
	}

	.team__toggler & {
		overflow: hidden;
	}

	&__caption {
		@include font(detailSmall);
		text-align: right;
		width: 100%;
		margin-top: $base1;

		&--alternative {
			@include font(baseSmallAlternate);
			margin-top: $base2;
			text-transform: none;
		}

		.blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
			@include app-container();
		}

		.block--highlight-header & {
			text-align: left;
		}

		.block--teaser .block__figure & {
			background-color: $colorBgBase;
			margin-top: -$base * 0.5;
			padding: $teaserCaptionPaddingSmall 0 $teaserCaptionPaddingSmall 0;
			position: relative;
			text-align: left;
			text-transform: uppercase;
			width: 100%;

			@include media($smallLayout) {
				padding: $teaserCaptionPaddingLarge;
				padding-left: 0;
				padding-right: 0;
			}

			@include media($mediumLayout) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.block--decorationPattern1 .block__figure & {
			text-align: right;
		}

		.block--decorationPattern2 .block__figure & {
			text-align: right;
		}

		.blocks:not(.blocks--nested) > .block--text & {
			display: flex;
			flex-direction: column;
		}

		.block--slideshow & {
			margin-top: 0;
			position: absolute;
			bottom: -($base4 + $slideShowNavigationTopSpacingSmall +
						$slideShowNavigationDotSizeSmall);

			@include media($smallLayout) {
				bottom: -($base4 + $slideShowNavigationTopSpacingLarge +
							$slideShowNavigationDotSizeLarge);
			}
		}
	}

	&__captionLabel {
		float: right;

		.block--decorationPattern3 & {
			float: left;
		}

		.block--decorationPattern4 & {
			float: left;
		}

		.image__caption--alternative & {
			width: 100%;
			display: block;
			text-align: center;
		}

		.blocks:not(.blocks--nested) > .block--text & {
			@include app-container-wide();
			text-align: left;

			@include media($mediumLayout) {
				@include app-undoContainer();
			}
		}

		.blocks:not(.blocks--nested) > .block--slideshow & {
			text-align: center;
		}

		.blocks:not(.blocks--nested) > .block--text &--alternate {
			@include font(baseSmallAlternate);
			text-align: center;
			margin-top: $baseHalf;

			@include media($largeLayout) {
				margin-top: $base1;
				width: staticColumnSize(10, 12, $gutterLarge);
			}
		}

		.block--slideshow & {
			float: none;
		}
	}

	&__credit {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		&,
		&.ugc p {
			@include font(imageCredit);
		}

		@include media($mediumLayout) {
			width: $containerMaxWidthNarrow;
		}

		.image__captionLabel + & {
			margin-top: $base * 0.5;
		}
	}

	&__img {
		max-width: 100%;
		width: 100%;
		height: 100%;

		.block--image & {
			height: auto;
			align-self: start;
		}

		.blocks--layoutTypeTwoColumn & {
			height: auto;
		}

		.html .body .image &:not(.js-loaded) {
			height: 1px;
		}

		.team__content & {
			height: auto;
		}

		.image--bg & {
			@include objectFit(cover);
			height: 100%;
			user-select: none;

			.slideshow--minHeightBg &,
			.block--minHeightBg & {
				height: auto;
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		.block--content-teaser .image--bg & {
			position: absolute;
			top: 0;
			object-fit: cover;
			object-position: center;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.blocks--nested .block--content-teaser .image--bg {
			position: relative;
			top: auto;
			height: auto;
			left: auto;
		}

		.block--image &,
		.gallery & {
			display: block;
		}

		.video &,
		.gallery:not(.gallery--layoutTypeJustified) & {
			@include objectFit(cover);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
		}

		.preview & {
			@include objectFit(cover);
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
		}

		.team__toggler & {
			@include ease(transform, $duration3, $easeOutQuad);
		}

		@include onHover(".team__toggler", " &") {
			transform: scale(1.05);
		}
	}

	&__imgWrapper {
		max-width: 100%;

		.preview &,
		.gallery:not(.gallery--layoutTypeJustified) & {
			display: block;
			padding-top: math.div(100%, $galleryColumnsImageRatio);
			position: relative;
		}
	}
	&__link {
		display: block;
		position: relative;

		.block--content-teaser .image--bg & {
			position: absolute;
			top: 0;
			object-fit: cover;
			object-position: center;
			left: 0;
			height: 100%;
			width: 100%;
		}
		.blocks--nested .block--content-teaser .image--bg & {
			position: relative;
			height: initial;
		}
	}
}
