.block {
	&__additionalContent {
		display: block;
		position: relative;
		@include app-container-wide();
		margin-bottom: $base5;
		text-align: center;

		@include media($smallLayout) {
			margin-bottom: $base10;
		}

		@include media($jigsawLayout) {
			margin-top: 0;
			display: none;
		}
	}
}
