.block {
	&__container {
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		order: 2;
		position: relative;
		width: 100%;
		z-index: 1;

		.block--linked & {
			text-decoration: none;
			color: $colorBase;
		}


		// delimited variants is used for blocks that have a background or borders
		// in this case we do not want the top space to collapse
		.block--delimited > & {
			padding-top: $blockVerticalSpacingSmall;

			@include media($smallLayout) {
				margin-top: $blockVerticalSpacingLarge;
			}
		}

		// remove the vertical rithm when they are organized into a grid layout
		.blocks--nested > .block:not(.block--delimited) > & {
			padding-bottom: 0;
		}

		.block--delimited + .block:not(.block--delimited) > & {
			padding-top: $blockVerticalSpacingSmall;

			@include media($smallLayout) {
				padding-top: $blockVerticalSpacingLarge;
			}

			// the vertical spacing needs to be removed because it's already provided by the grid gutters
			.blocks--nested > & {
				@include media($columnsBreakpoint) {
					padding-top: 0;
				}
			}
		}

		.block--collapsable & {
			padding-bottom: 0;
			@include media($smallLayout) {
				padding-bottom: 0;
			}
		}

		.block--layoutHSizeDefault .blocks--nested.blocks--layoutTypeSingleColumn .block--hSizeDefault & {
			margin-left: $gutterMedium * 0.5;

			@include media($columnsBreakpoint) {
				margin-left: $gutterLarge * 0.5;
			}
		}


		.block--hPositionBegin > & {
			align-items: flex-start;
		}

		.block--hPositionCenter > & {
			align-items: center;
		}

		.block--hPositionEnd > & {
			align-items: flex-end;
		}

		.block--teaser > & {
			position: relative;
			//margin-top: 100%;
		}

		.block--vAlignBegin > & {
			justify-content: flex-start;
		}

		.block--vAlignEnd > & {
			justify-content: flex-end;
		}

		.block--vAlignCenter > & {
			justify-content: center;
		}

		.block--vAlignJustified > & {
			justify-content: flex-start;
			// the content will decide who is taking space and who is not
		}

		.block--imgOrientationPortrait > & {
			@include media($smallLayout) {
				@include conditionalContainer();
				@include staticColumns();
			}
		}

		.block--minHeightBg > & {
			justify-content: center;
			min-height: 100%;
			position: absolute;
		}
		.block--minHeightFixed > & {
			justify-content: center;
			min-height: 100%;
			position: absolute;
		}
		.block--minHeightBg.block--hasCaptions > &,
		.block--minHeightFixed.block--hasCaptions > & {
			top:0;
			min-height: calc(100% - 34px);
		}


		// BLOCK SPECIFIC

		.block--content-teaser & {
			margin-top: $base2;

			@include media($mediumLayout) {
				margin-top: $base3;
			}
		}

		.block--highlight-header & {
			flex-direction: column;

			@include media($mediumLayout) {
				@include app-container-wide();
				flex-direction: row;
			}
		}


		.blocks:not(.blocks--nested) > .block--content-teaser > & {
			@include media($smallLayout) {
				margin-bottom: - contentTeaserVSpacing($smallLayout);
				margin-top: 0;
				max-width: $containerMaxWidthWide;
				padding-right: $containerPaddingSmall;
				position: relative;
				top: - contentTeaserVSpacing($smallLayout);
			}
		}

		.block--image & {
			padding-bottom: 0;
		}


		.block--slideshow & {
			padding-bottom: $base8;

			@include media($smallLayout) {
				padding-bottom: $base8;
			}
		}


		.block--team & {
			z-index: 2;
		}
	}
}
