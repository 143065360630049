@mixin separator($selector: '&', $alignment: 'top') {
	#{$selector}:not(.block--separatorHidden) {
		position: relative;

		&::before {
			@include app-container-wide();
			content: '';
			#{$alignment}: 0;
			background-color: $colorBase;
			top: 1px;
			height: 1px;
			left: $containerPaddingXSmall;
			max-width: $containerMaxWidthWide - $containerPaddingSmall * 2;
			position: absolute;
			right: $containerPaddingXSmall;
			width: auto;

			@each $breakpoint, $containerPadding in $containerPaddings {
				@include media($breakpoint) {
					left: $containerPadding;
					max-width: $containerMaxWidthWide - $containerPadding * 2;
					right: $containerPadding;
				}
			}
		}
	}

	// NOTE: evtualy not needed in new design
	// #{$selector}:not(.block--separatorHidden).block--separatorSizeWide {
	// 	&::before {
	// 		@include app-container-wide();
	// 		max-width: $containerMaxWidthWide - $containerPaddingSmall * 2;
	// 		width: auto;

	// 		@each $breakpoint, $containerPadding in $containerPaddings {
	// 			@include media($breakpoint) {
	// 				max-width: $containerMaxWidthWide - $containerPadding * 2;
	// 			}
	// 		}
	// 	}
	// }

	// #{$selector}:not(.block--separatorHidden).block--separatorSizeContent {
	// 	&::before {
	// 		@include app-container-wide();
	// 		width: auto;
	// 		max-width: 108rem;
	// 	}
	// }

	// #{$selector}:not(.block--separatorHidden).block--separatorSizeContained {
	// 	&::before {
	// 		@include app-container(true);
	// 		width: auto;
	// 		max-width: $containerMaxWidthNarrow - $containerPaddingXSmall * 2;

	// 		@each $breakpoint, $containerPadding in $containerPaddings {
	// 			@include media($breakpoint) {
	// 				max-width: $containerMaxWidthNarrow - $containerPadding * 2;
	// 			}
	// 		}
	// 	}
	// }
}
