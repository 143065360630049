.button {
	@include ease(color, $hoverDuration);
	@include button();
	@include font(base);
	border-color: currentColor;
	border-style: solid;
	border-width: $buttonBorderSize;
	padding: $inputPadding;
	text-align: center;

	@include onHover() {
		color: $colorHighlight;
	}

	.newsletterSignup & {
		@include font(form);
	}

	&:disabled,
	&.js-disabled {
		cursor: default;
		opacity: $opacityInputDisabled;
		pointer-events: none;
	}

	.block.block--content-teaser &--alternate {
		&::after,
		&::before {
			display: none;
		}
	}

	&--accordion {
		@include font(newCategory);
		@include ease(background-color border-color, $hoverDuration);
		background: $colorBase;
		color: $colorBgBase;
		border-color: $colorSeparatoraAccordion;
		height: pxToRem(34);
		width: 100%;
		margin-top: $vwS20;

		//>=428px
		@include media($smallNewLayout) {
			height: $base4;
		}
		//>=768px
		@include media($mediumNewLayout) {
			height: $base3;
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			height: $accordionHeight;
			margin-top: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base2;
			height: $accordionHeight;
		}

		@include onHover() {
			background-color: $colorHighlight;
			border-color: $colorHighlight;
			color: $colorBgBase;
		}

		.block--vividColorVersion & {
			background-color: $colorBgBase;
			color: $colorBase;
			border-color: $colorBgBase;

			@include onHover() {
				background-color: $colorBase;
			}
		}
	}

	&--arrow {
		& + & {
			margin-left: $base2;
		}
	}

	&--arrows {
		border: 0;
		color: $colorHighlight;
		width: $base5;
		height: $base5;

		@include onHover("&", "svg") {
			fill: $colorBase;
		}
	}

	&--share {
		@include font(highlightMedium);
		border: 0;
		color: $colorHighlight;
		padding: 0;

		@include onHover() {
			color: $colorBase;
		}
	}

	&--shareLink {
		@include font(baseSmall);
		font-family: $fontFamilyAlt;
		border: 0;
		color: currentColor;
		padding: 0;

		@include onHover() {
			color: $colorHighlight;
		}
	}

	&--slideshowTrigger {
		border: 0;
		background-color: $colorInactive;
		border-radius: 50%;
		font-size: 0;
		height: $slideShowNavigationDotSizeSmall;
		line-height: 0;
		padding: 0;
		text-indent: -10000px;
		width: $slideShowNavigationDotSizeSmall;

		&.js-current {
			background-color: $colorBase;
		}

		@include media($columnsBreakpoint) {
			height: $slideShowNavigationDotSizeLarge;
			width: $slideShowNavigationDotSizeLarge;
		}
	}

	&--toggler {
		color: $colorHighlight;

		&.js-toggled {
			display: none;
		}

		.blocks--nested:not(.tni-js-collapsed) & {
			display: inline-flex;
		}
	}

	&--link {
		@include app-link();
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		min-width: 0;
		text-align: left;
	}

	&--menuToggler {
		@include ease(color, $durationBase, $easeBase);
		cursor: pointer;
		align-items: center;
		border: none;
		display: flex;
		height: $menuTogglerSizeSmall;
		padding: 0;
		width: $menuTogglerSizeSmall;

		@include onHover() {
			color: $colorHighlight;
		}

		@include media($largeLayout) {
			height: $menuTogglerSizeLarge;
			width: $menuTogglerSizeLarge;
		}

		[data-current-context]:not([data-current-context="menu"])
			.headerButtonMenu__item:first-child
			&[data-for-context="menu"] {
			@include media($largeLayout) {
				position: fixed;
			}
		}

		[data-type="svg"] {
			width: 100%;
		}

		&::before {
			@include ease(
				transform width top background-color,
				$durationBase,
				$easeBase
			);
			background-color: $colorBase;
			content: "";
			height: $buttonIconBarSize;
			left: 1px;
			position: absolute;
			top: 0;
			transform-origin: top left;
			transform-style: flat;
			visibility: inherit;
			width: 100%;
		}

		&.js-toggled::before {
			transform: rotate(45deg) translateY(-1px);
			width: $menuTogglerDiagonalSizeSmall;

			@include media($largeLayout) {
				width: $menuTogglerDiagonalSizeLarge;
			}
		}

		&::after {
			@include ease(
				transform bottom background-color,
				$durationBase,
				$easeBase
			);
			background-color: $colorBase;
			bottom: 0;
			content: "";
			height: $buttonIconBarSize;
			left: 0;
			position: absolute;
			transform-origin: bottom left;
			transform-style: flat;
			visibility: inherit;
			width: 100%;
		}

		&.js-toggled::after {
			transform: rotate(-45deg) translateY(1px);
			width: $menuTogglerDiagonalSizeSmall;

			@include media($largeLayout) {
				width: $menuTogglerDiagonalSizeLarge;
			}
		}

		@include onHover() {
			&::after,
			&::before {
				background-color: $hoverColor;
			}
		}
	}

	&--prominent {
		@include media($columnsBreakpoint) {
			min-width: ($buttonMinWidth * 2 + $base2);
		}
	}

	&--seamless {
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		min-width: 0;
	}

	&--secondary {
		background-color: transparent;
	}

	&--toggler {
		border-width: 0;
		padding: 0;

		&::before {
			display: none;
		}

		.block--textSizeDefault & {
			display: inline-block;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
			width: auto;

			&::before {
				display: none;
			}

			@include media($smallLayout) {
				width: auto;
				margin-top: 0;
			}
		}

		.ugc p > & {
			margin-left: $base1;
		}
	}

	&--toggler.button--iconToggler {
		width: 100%;
		z-index: 1;

		&:not(:only-child):not(:first-child) {
			margin-top: $base2;

			@include media($smallLayout) {
				margin-top: $base3;
			}
		}
	}

	&--trackersAcceptAll {
		@include font(detailMedium);
		background-color: $colorBase;
		border: 0;
		color: $colorBgBase;
		padding-left: $base2;
		padding-right: $base2;

		text-transform: uppercase;
		@include onHover() {
			color: $colorHighlight;
		}

		// width: staticColumnSize(1, 2, $gutterMedium);
		// .trackers.js-confirmMode & {
		// 	display: none;
		// }
	}

	&--trackersRejectAll {
		@include font(detailMedium);
		border: 0;
		padding-left: $base2;
		padding-right: $base2;
		text-transform: uppercase;
		text-decoration: underline;

		// width: staticColumnSize(1, 2, $gutterMedium);
		// .trackers.js-confirmMode & {
		// 	display: none;
		// }
	}

	&--trackersOptions {
		display: none;
		// text-align: center;
		// width: staticColumnSize(1, 1, $gutterMedium);
	}

	&--trackersSave {
		@include font(detailMedium);
		background-color: $colorBase;
		border: 0;
		color: $colorBgBase;
		padding-left: $base2;
		padding-right: $base2;

		text-transform: uppercase;
		@include onHover() {
			color: $colorHighlight;
		}
	}

	&--trackersOk {
		display: none;
		// @include font(detailMedium);
		// background-color: $colorBase;
		// border: 0;
		// color: $colorBgBase;
		// padding-left: $base2;
		// padding-right: $base2;

		// text-transform: uppercase;
		// @include onHover() {
		// 	color: $colorHighlight;
		// }

		// .trackers:not(.js-confirmMode) & {
		// 	display: none;
		// }
	}

	&--newsletterSubmit {
		.newsletterSignup .form &,
		.form &,
		& {
			@include font(formSubmit);
			@include app-newsletterField;
			background-color: $colorBgInverted;
			border: none;
			color: $colorInverted;
			text-transform: uppercase;
			width: dynamicColumnSize(1, 1, $gutterMedium);

			@include onHover() {
				color: $colorInverted;
				background-color: $colorHighlight;
			}

			@include media($smallLayout) {
				width: dynamicColumnSize(1, 3, $gutterMedium);
				order: 1;
			}

			@include media($mediumLayout) {
				width: dynamicColumnSize(1, 3, $gutterMedium);
				order: 0;
			}

			// @include ease(opacity color background-color, $hoverDuration);
			// border: none;
			// display: inline-flex;
			// flex-grow: 0;
			// flex-shrink: 0;
			// margin: 0;
			// text-transform: uppercase;
			// right: 0;
			// min-height: 100%;

			// &:disabled,
			// &.js-disabled {
			// 	display: inline-flex;
			// 	opacity: 0;
			// 	position: absolute;
			// }

			// @include onHover() {
			// 	color: $colorInverted;
			// 	background-color: $colorBgInverted;
			// }
		}

		// .form.js-response & {
		// 	display: none;
		// }
	}

	&--prev {
		svg {
			transform: scaleX(-1);
		}
	}

	.booking & {
		min-width: 0;
		width: 100%;
	}

	// buttons inside forms
	.form & {
		// margin-bottom: $base2;
		// margin-left: 0;
		// margin-top: $base2;
		min-width: $buttonMinWidth;
		// padding: $inputPadding;

		// .newsletterSignup & {
		// 	padding: $base $inputPadding $base $inputPadding;
		// }
	}

	.form > &:first-child {
		margin-top: 0;
	}

	.form__buttons > & {
		margin-left: $base2;
		margin-top: 0;
	}

	.field--search .field__input--text + & {
		flex-grow: 0;
		flex-shrink: 1;
		margin-bottom: 0;
		margin-left: $base2;
		margin-top: 0;
		min-width: 0;

		@include media($columnsBreakpoint) {
			min-width: $buttonMinWidth;
		}
	}

	.block__additionalContent & {
		@include font(baseItalic);
		border: none;
		color: $colorBase;
		padding: 0;
		position: inline-block;

		&::after {
			background-color: currentColor;
			bottom: 0.15em;
			content: "";
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	.block--breadcrumb-links & {
		@include font(highlightMedium);
		color: $colorHighlight;
		order: 2;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		border: none;

		@include onHover() {
			color: $colorHighlightLight;
		}
	}

	.block--highlight-header & {
		@include font(highlightMedium);
		color: $colorHighlight;
		border: none;
		padding: 0;
		margin: 0;
	}

	.block--teaser &,
	.block--content-teaser & {
		@include font(baseItalic);
		border: none;
		color: $colorBase;
		padding: 0;

		&::after {
			background-color: currentColor;
			bottom: 0.15em;
			content: "";
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	.block--content-teaser & {
		@include font(headerServiceMenu);
		font-style: normal;
		font-weight: $weightSemiBold;
		text-transform: uppercase;
		margin-top: $base;
	}

	.field__fields--grid & {
		width: auto;

		@include media($columnsBreakpoint) {
			align-self: flex-end;
			min-width: none;
			width: dynamicColumnSize(1, 3, $gutterMedium);
		}
	}

	&__icon {
		.button--shareLink & {
			width: $base3;
			height: $base3;
		}

		.button--toggler & {
			@include ease(transform, $durationBase);
			border-width: 0;
			display: inline-block;
			position: relative;
			order: 1;
			margin-left: 0;

			@include media($smallLayout) {
				//width: $base5;
				//height: $base3;
			}
		}

		.button--toggler.js-toggled & {
			transform: scaleY(-1);
		}
	}

	&__iconBar {
		@include ease(background-color opacity, $durationBase, $easeBase);
		background-color: $colorBase;
		height: $base * 0.3;
		display: block;
		width: 100%;
		opacity: 1;

		.button.js-toggled & {
			opacity: 0;
		}

		@include onHover(".button--menuToggler", "&") {
			background-color: $hoverColor;
		}
	}

	&__label {
		.button--trackersDetails.js-toggled &--show,
		.button--trackersDetails:not(.js-toggled) &--hide,
		.trackers.js-expanded .button--trackersOptions &--show,
		.trackers:not(.js-expanded) .button--trackersOptions &--hide {
			display: none;
		}

		.button--toggler & {
			@include font(highlightMedium);
			color: $colorHighlight;
			flex-grow: 0;
			margin-left: $base1;
			order: 2;
			position: relative;
			text-decoration: none;
		}

		.button--alternate & {
			@include font(baseSmallSemiBold);
			background-color: $colorBgInput;
			padding: $base1 $base2;
			pointer-events: all;
		}

		&--default {
			display: block;

			.button--toggler.js-toggled & {
				display: none;
			}

			.block--collapsable .button--toggler.js-toggled & {
				display: block;
			}
		}

		&--toggled {
			display: none;

			.button--toggler.js-toggled & {
				display: block;
			}

			.block--collapsable .button--toggler.js-toggled & {
				display: none;
			}
		}
	}
}
