.block {
	&__header {
		@include conditionalContainer();
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;


		.block--noneHeader & {
			display: none;
		}

		.blocks--nested & {
			@include app-undoContainer();
		}


		//VARIANTS

		.block--imgOrientationPortrait & {
			@include media($smallLayout) {
				padding-left: $base5;
				padding-right: 0;
			}
		}

		.blocks:not(.blocks--nested) > .block--hSizeSmall & {
			@include conditionalContainer(true);
			@include media($columnsBreakpoint) {
				@include conditionalContainer(true);
			}
		}

		//BLOCK SPECIFIC

		.block--text & {
			@include app-undoContainer();
			position: relative;
			z-index: 2;
		}

		.block--content-teaser & {
			@include app-undoContainer();
		}

		.block--highlight-header & {
			order: 2;
		}

		.block--teaser & {
			@include media($jigsawLayout) {
				//float: right;
				padding-right: 0;
				padding-left: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
				top: -$base2;
				position: relative;
			}

			@include media($largeLayout) {
				padding-left: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
			}
		}

		.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
			@include media($jigsawLayout) {
				padding-right: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
				padding-left: 0;
			}

			@include media($largeLayout) {
				padding-right: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
			}
		}

		.block--video & {
			align-items: flex-start;
			bottom: $videoTitleSpacingSmall + getFontSize(titleMedium);
			// margin-left: $base2;
			margin-right: 0;
			margin-left: auto;
			order: 2;
			padding-left: $base2;
			padding-right: $base2;
			position: relative;
			left: auto;
			right: 0;
			width: auto;
			z-index: 3;

			&::before {
				@include ease(left, 0.6s, $easeOutQuad);
				background-color: $colorBgBase;
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}

			@include media($smallLayout) {
				$fontSizeSubtitle: getFontSize(baseSmallItalic);
				$lineHeightSubtitle: getLineHeight(baseSmallItalic);

				bottom: - (($fontSizeSubtitle * $lineHeightSubtitle) - $fontSizeSubtitle);
				// left: $containerPaddingSmall;
				margin-left: 0;
				padding-left: $base3;
				padding-right: $base3;
				right: $containerPaddingSmall;
				position: absolute;
				// width: auto;
			}

			@include media($mediumLayout) {
				left: auto;
				padding-left: $base5;
				padding-right: $base5;
				right: $containerPaddingMedium;
				// width: staticColumnSize(11, 12, $gutterMedium, -$containerPaddingMedium * 2);
			}

			@include media($largeLayout) {
				right: $containerPaddingLarge;
				// width: auto;
			}
		}

		.block--video:hover &::before {
			left: $base1;

			@include media($largeLayout) {
				left: $base3;
			}
		}


	
		.block--image & {
			align-items: flex-start;
			background-color: $colorBgBase;
			bottom: $videoTitleSpacingSmall + getFontSize(titleMedium);
			margin-left: $base2;
			order: 2;
			padding-left: $base2;
			padding-right: $base2;
			position: relative;
			right: 0;
			width: 100%;
			z-index: 3;

			@include media($smallLayout) {
				$fontSizeSubtitle: getFontSize(baseSmallItalic);
				$lineHeightSubtitle: getLineHeight(baseSmallItalic);

				bottom: - (($fontSizeSubtitle * $lineHeightSubtitle) - $fontSizeSubtitle);
				left: $containerPaddingSmall;
				margin-left: 0;
				padding-left: $base3;
				position: absolute;
				width: auto;
			}

			@include media($mediumLayout) {
				left: auto;
				padding-left: $base5;
				padding-right: $base5;
				right: $containerPaddingMedium;
				width: staticColumnSize(11, 12, $gutterMedium, -$containerPaddingMedium * 2);
			}

			@include media($largeLayout) {
				width: auto;
			}
		}

		.block--video.block--hSizeFull & {
			//bottom: $base * 10;

			@include media($columnsBreakpoint) {
				//bottom: $base * 20;
			}
		}

		.block--video.js-loaded & {
			display: none;
		}

		.block--content-teaser.block--reverseTitleAndCategory & {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}
