.block {
	&__figureContainer {
		.block--teaser & {
			display: flex;
			flex-direction: row;
			position: relative;
			width: 100%;
			@include app-container-wide();

			/*@include media($mediumLayout) {
				padding-left: $containerPaddingMedium;
				padding-right: $containerPaddingMedium;
				margin-left: auto;
				margin-right: auto;
				max-width: $containerMaxWidthWide;
			}

			@include media($jigsawLayout) {
				padding-left: $containerPaddingLarge;
				padding-right: $containerPaddingLarge;
				margin-left: auto;
				margin-right: auto;
				max-width: $containerMaxWidthWide;
			}*/
		}
	}
}
