.header {
	padding-top: $base1;

	@include media($mediumNewLayout) {
		padding-top: $base3;
	}

	@include media($largeNewLayout) {
		padding-top: $vwL100;
	}

	@include media($xLargeNewLayout) {
		padding-top: $base10;
	}

	&--darkColorVersion:not(.js-coveredByNewMenu),
	&--vividColorVersion:not(.js-coveredByNewMenu) {
		color: $colorBgBase;
	}

	&--darkColorVersion:not(.js-coveredByNewMenu) {
		background-color: $darkColorBg;
	}

	&--lightColorVersion:not(.js-coveredByNewMenu) {
		background-color: $lightColorBg;
	}

	&--veryLightColorVersion:not(.js-coveredByNewMenu) {
		background-color: $veryLightColorBg;
	}

	&--vividColorVersion:not(.js-coveredByNewMenu) {
		background-color: $vividColorBg;
	}

	&__langMenu {
		display: none;
		@include fadeIn($durationFast, $easeBase);

		.body.js-scrolledDown & {
			@include fadeOut($durationFast, $easeBase);
		}

		@include media($mediumNewLayout) {
			display: block;
		}
	}

	&__logo {
		@include app-link();
		display: inline-block;
		margin-left: 0;
		padding-left: 0;
		position: relative;
		width: staticColumnSize(3, 6, $newGutterSmall);
		z-index: 4;

		@include media($mediumNewLayout) {
			width: staticColumnSize(3, 12, $newGutterSmall);
			z-index: 1;
		}

		@include media($largeNewLayout) {
			margin-left: staticColumnSize(1, 12, $newGutterLarge);
			padding-left: $newGutterLarge;
			width: staticColumnSize(2, 12, $newGutterLarge);
		}
	}

	&__nav {
		@include ease(top, $durationFast, $easeBase);
		align-items: center;
		display: flex;
		height: $headerNavSmallHeight;
		position: fixed;
		right: 0;
		top: $headerNavSmallPosTop;
		z-index: 100;

		@include media($mediumNewLayout) {
			height: $headerNavMediumHeight;
			top: $headerNavMediumPosTop;
		}

		@include media($largeNewLayout) {
			height: $headerNavLargeHeight;
			top: $headerNavLargePosTop;
		}

		@include media($xLargeNewLayout) {
			height: $headerNavXLargeHeight;
			top: $headerNavXLargePosTop;
		}

		.body.js-scrolledDown & {
			top: 0;
		}
	}

	&__wrapper {
		@include app-newcontainer();
	}
}
