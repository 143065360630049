.block {
	&__content {
		display: flex;
		flex-direction: column;
		order: 4;
		position: relative;
		z-index: 1;


		//VARIANTS

		.block--hSizeFull > .block__container > & {
			@include media($columnsBreakpoint) {
				max-width: none;
			}
		}

		.blocks:not(.blocks--nested) > .block--hSizeFull:not(.block--group) & {
			@include app-undoContainer();

			@include media($columnsBreakpoint) {
				@include app-undoContainer();
			}
		}

		.block--collapsable & {
			@include ease(height, $durationBase, $easeBase);
			overflow: hidden;
		}

		.block--group.block--layoutHSizeDefault & {
			@include app-container-wide();
		}

		.block--group.block--layouthSizeDefault & {
			@include app-container(true);
		}

		// reset container max width for nested blocks
		.block--group.block--layouthSizeDefault & &,
		.block--group.block--layoutHSizeDefault & & {
			@include app-undoContainer();
		}


		.block--vAlignJustified & {
			flex-grow: 1;
		}

		.blocks--nested & {
			@include app-undoContainer();
		}

		.block--imgOrientationPortrait & {
			@include media($smallLayout) {
				padding-left: $base5;
				padding-right: 0;
			}
		}

		.block--newNewsletter & {
			position: static;
		}


		// BLOCK SPECIFIC

		.block--content-teaser & {
			flex-direction: column-reverse;
			align-items: center;
		}

		.block--text .block__header + & {
			margin-top: $blockHeaderTopSpacingSmall;

			@include media($smallLayout) {
				margin-top: $blockHeaderTopSpacingLarge;
			}

			@include media($mediumLayout) {
				margin-top: $blockHeaderTopSpacingLargeAlternate;
			}
		}


		.blocks:not(.blocks--nested) > .block--image.block--hSizeDefault > .block__container > .block__main > & {
			width: 100%;

			@include media($mediumLayout) {
				padding-left: 0;
				padding-right: 0;
				max-width: staticColumnSize(10, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				max-width: staticColumnSize(8, 12, $gutterLarge);
			}
		}


		.block--highlight-header & {
			order: 1;
		}

		.block--highlight-header .block__header + & {
			margin-bottom: $base1;
		}


		.block--text & {
			@include media($smallLayout) {
				max-width: $containerMaxWidthNarrow;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-items: center;
				width: staticColumnSize(10, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: auto;
			}
		}

		.block--team.block--layoutTwoColumns & {
			flex-direction: row;
			justify-content: center;
		}

		.block--text.block--headerContentSpacingWide .block__header + & {
			margin-top: $blockHeaderTopSpacingSmallAlternate;

			@include media($mediumLayout) {
				margin-top: $blockHeaderTopSpacingLargeAlternate;
			}
		}

		.block--layout .blocks--nested .block__header + & {
			margin-top: 0;
		}

		.block---breadcrumb-links {
			text-align: center;
		}

		.block--teaser & {
			@include app-container-wide();

			left: 50%;
			margin-top: 0;
			pointer-events: none;
			//
			top: 0;
			transform: translateX(-50%);

			@include media($jigsawLayout) {
				position: absolute;
				height: 100%;
			}

			> * {
				pointer-events: all;
				width: 36%;

				@include media($mediumLayout) {
					width: 40%;
				}

				@include media($jigsawLayout) {
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					width: staticColumnSize(3, 12, $gutterLarge);
				}
			}
		}

		.block--video & {
			order: 1;
			background-color: $colorBgVideo;
		}
	}
}
