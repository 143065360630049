@use "sass:math";

.block {
	&__figure {
		flex-grow: 0;
		flex-shrink: 0;
		position: relative;
		z-index: 0;


		&::before {
			//@include app-container();
			@include ease(background-size, 1s, $easeOutQuad);
			content: '';
			background-origin: content-box;
			background-repeat: no-repeat;
			height: 100%;
			left: 0;
			max-width: unset;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;

			@include media($jigsawLayout) {
				float: right;
				padding-left: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
			}

			@include media($largeLayout) {
				padding-left: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
			}


			.block--teaser.block--hasCaptions & {
				height: calc(100% - (#{getFontSize(detailSmall)} * #{getLineHeight(detailSmall)} + (#{$teaserCaptionPaddingSmall} * 2)) + 1px);

				@include media($smallLayout) {
					height: calc(100% - (#{getFontSize(detailSmall)} * #{getLineHeight(detailSmall)} + #{$teaserCaptionPaddingLarge * 2}) + 1px);
				}
			}

			.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
				@include media($jigsawLayout) {
					padding-right: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
					padding-left: 0;
				}

				@include media($largeLayout) {
					padding-right: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
				}
			}

			// reference values grabbed from the design
			$w: 1280;
			$h: 828;
			// the additional pixel is needed for retina display that have rounding errors in some cases
			$min: -1px;
			$max: calc(100% + 1px);
			$hoverOffset: 30;

			$animationStart: 500%;

			.block--decorationPattern1 & {
				background-image:
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					//linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%);
				background-position:
					$min $max,
					$max $min,
					//$max $max,
					$min $min;
				background-size:
					(math.div(600, $w) * 100%) (math.div(40, $h) * 100%),
					(math.div(530, $w) * 100%) (math.div(175, $h) * 100%),
					//(644 / $w * 100%) (92 / $h * 100%),
					0% 100%;

				// &:hover {
				// 	background-size:
				// 		100% (322 / $h * 100%),
				// 		100% (276 / $h * 100%),
				// 		//100% (92 / $h * 100%),
				// 		0% 100%;
				// }
			}

			.block--decorationPattern1:hover & {
				@include ease(background-size, 0.6s, $easeOutQuad);
				background-size:
					(math.div($jigsawHover * (600 - $hoverOffset), $w) * 100%) (math.div(40, $h) * 100%),
					(math.div($jigsawHover * (530 - $hoverOffset), $w) * 100%) (math.div(175, $h) * 100%),
					//($jigsawHover * 644 / $w * 100%) (92 / $h * 100%),
					0% 100%;
			}

			.block--decorationPattern1:not(.js-intersectedJirgsaw) & {
				background-size:
					100% (math.div(40, $h) * 100%),
					100% (math.div(175, $h) * 100%),
					//100% (92 / $h * 100%),
					101% 100%;
			}

			.block--decorationPattern2 & {
				background-image:
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					//linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%);
				background-position:
					$min $max,
					$max $min,
					//$max $max,
					$min $min;
				background-size:
					(math.div(200, $w) * 100%) (math.div(315, $h) * 100%),
					(math.div(550, $w) * 100%) (math.div(115, $h) * 100%),
					//(184 / $w * 100%) (322 / $h * 100%),
					0% 100%;
			}

			.block--decorationPattern2:hover & {
				@include ease(background-size, 0.6s, $easeOutQuad);
				background-size:
					(math.div($jigsawHover * (200 - $hoverOffset), $w) * 100%) (math.div(315, $h) * 100%),
					(math.div($jigsawHover * (550 - $hoverOffset), $w) * 100%) (math.div(115, $h) * 100%),
					//($jigsawHover * 184 / $w * 100%) (322 / $h * 100%),
					0% 100%;
			}

			.block--decorationPattern2:not(.js-intersectedJirgsaw) & {
				background-size:
					100% (math.div(315, $h) * 100%),
					100% (math.div(115, $h) * 100%),
					//100% (322 / $h * 100%),
					101% 100%;
			}

			.block--decorationPattern3 & {
				background-image:
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%);
				background-position:
					$min $min,
					$max $max,
					$min $min;
				background-size:
					(math.div(700, $w) * 100%) (math.div(95, $h) * 100%),
					(math.div(500, $w) * 100%) (math.div(195, $h) * 100%),
					0% 100%;
			}

			.block--decorationPattern3:hover & {
				@include ease(background-size, 0.6s, $easeOutQuad);
				background-size:
					(math.div($jigsawHover * (700 - $hoverOffset), $w) * 100%) (math.div(95, $h) * 100%),
					(math.div($jigsawHover * (500 - $hoverOffset), $w) * 100%) (math.div(195, $h) * 100%),
					0% 100%;
			}

			.block--decorationPattern3:not(.js-intersectedJirgsaw) & {
				background-size:
					100% (math.div(95, $h) * 100%),
					100% (math.div(195, $h) * 100%),
					101% 100%;
			}


			.block--decorationPattern4 & {
				background-image:
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%),
					linear-gradient($colorBgBase 0%, $colorBgBase 100%);
				background-position:
					$min $min,
					$max $max,
					$min $min;
				background-size:
					(math.div(600, $w) * 100%) (math.div(95, $h) * 100%),
					(math.div(480, $w) * 100%) (math.div(85, $h) * 100%),
					0% 100%;
			}

			.block--decorationPattern4:hover & {
				@include ease(background-size, 0.6s, $easeOutQuad);
				background-size:
					(math.div($jigsawHover * (700 - $hoverOffset), $w) * 100%) (math.div(95, $h) * 100%),
					(math.div($jigsawHover * (480 - $hoverOffset), $w) * 100%) (math.div(85, $h) * 100%),
					0% 100%;
			}

			.block--decorationPattern4:not(.js-intersectedJirgsaw) & {
				background-size:
					100% (math.div(95, $h) * 100%),
					100% (math.div(85, $h) * 100%),
					101% 100%;
			}
		}

		.block--content-teaser &::before {
			content:none;
		}
		/*
		&::after {
			@include app-container();
			@include ease(background-size, 0.6s, $easeOutQuad, 3s);
			content: '';
			background-origin: content-box;
			background-repeat: no-repeat;
			height: 100%;
			left: 0;
			max-width: unset;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;

			.block--withLinks & {
				@include media($smallLayout) {
					display: none;
				}
			}

			.block--teaser:nth-child(2n + 1) & {
				transform: scaleX(-1);
			}

			// reference values grabbed from the design
			$w: 1280;
			$h: 828;
			// the additional pixel is needed for retina display that have rounding errors in some cases
			$min: -1px;
			$max: calc(100% + 1px);

			.block--decorationPattern1 &,
			.block--decorationPattern2 &,
			.block--decorationPattern3 & {
				background-image:
					linear-gradient($colorBgBase 0%, $colorBgBase 100%);
				background-position:
					$min $min;
				background-size:
					(460 / $w * 100%) (184 / $h * 100%);
			}

			.block--decorationPattern1:not(.js-intersectedJirgsaw) &,
			.block--decorationPattern2:not(.js-intersectedJirgsaw) &,
			.block--decorationPattern3:not(.js-intersectedJirgsaw) & {
				background-size:
					100% (184 / $h * 100%);
			}
		}*/

		.block--withLinks & {
			text-decoration: none;
			color: inherit;
		}

		.blocks:not(.blocks--nested) > .block--teaser & {
			//@include app-container-wide();
			width: 100%;
			height: auto;
			order: 1;

			@include media($jigsawLayout) {
				padding-left: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
			}


			@include media($largeLayout) {
				padding-left: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
			}
		}

		.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
			@include media($jigsawLayout) {
				padding-right: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
				padding-left: 0;
			}

			@include media($largeLayout) {
				padding-right: staticColumnSize(3, 12, $gutterLarge, $gutterLarge);
			}
		}


		// VARIANTS
		.block--teaser:not(.block--minHeightBg) & {
			bottom: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
		}

		// BLOCK SPECIFIC

		.blocks:not(.blocks--nested) > .block--content-teaser > & {
			@include media($mediumLayout) {
				align-self: flex-end;
				width: staticColumnSize(9, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(9, 12, $gutterLarge);
			}
		}

		&--video {
			overflow: hidden;
		}
	}
}
