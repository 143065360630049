.scrollHint {
	bottom: $base5;
	pointer-events: none;
	position: fixed;
	width: 100%;
	z-index: 3;

	&.js-hidden {
		@include fadeOut($duration4);
	}


	&__container {
		@include app-container-wide();
		text-align: right;

		@include media($mediumLayout) {
			transform: translateX(#{$containerPaddingMedium*0.5 + $menuTogglerSizeSmall*0.5});
		}
		@include media($largeLayout) {
			transform: translateX(#{$containerPaddingLarge*0.5 + $menuTogglerSizeLarge*0.5});
		}
	}
}
