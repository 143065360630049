.logo {
	display: inline-block;
	height: $logoHeightSmall;
	width: auto;

	@include media($smallLayout) {
		height: $logoHeightLarge;
	}

	[data-type="svg"] {
		height: 100%;
		width: auto;
	}

	.header &,
	.newMainNavs & {
		display: block;
		height: $newLogoHeightSmall;

		//>=768px
		@include media($mediumNewLayout) {
			height: $newLogoHeightMedium;
		}
		//>=1280px
		@include media($largeNewLayout) {
			height: $newLogoHeightLarge;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			height: $newLogoHeightXLarge;
		}
	}
}
